import React, { useEffect } from 'react';

import { Container, Row, Col, Button} from 'react-bootstrap';
import { gsap } from "gsap";

import './Mission.css';

import HandMLogo from "../images/H&M logo.svg"

import CLOLogo from "../images/CLO logo.svg"
import BROWZWEARLogo from "../images/Browzwear logo.svg"
import LectraLogo from "../images/Lectra logo.svg"
import OptitexLogo from "../images/Optitex logo.svg"

import Arrow from '../icons/arrow.svg'
import Cube from '../icons/cube.svg'
import Tree from '../icons/tree.svg'

const Approach = () => {
    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".mission-content",
                start: "top bottom",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.header', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, })
            tl.from('.mission-text-container', { opacity:0, ease:"power3.inOut", duration: 1 }, .6)
            tl.from('#line', { width:0, ease:"ease.inOut", duration: 1.2 }, .8)  
    }, []);


    useEffect(() => {     
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".mission-content",
                start: "top center",
                ease: "power2",
                toggleActions: "play none none none",
                markers: false
            }
        });
            tl.fromTo('.action-text-first', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, }, .2)
            tl.fromTo('.action-arrow-first', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .2)
            tl.fromTo('.action-button-text-first',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, .8)
            tl.fromTo('.mission-cube',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
            tl.fromTo('.action-text-second', {y:150, ease:"power3.inOut", transformOrigin: 'right top', skewY: 8, stagger: { amount: 1.4 } }, {y:0, ease:"power3.inOut", duration: 1.2, skewY: 0, },.4)
            tl.fromTo('.action-arrow-second', {x:-250, ease:"power3.inOut"}, {x:0, ease:"power3.inOut", duration: 1.2}, .4)
            tl.fromTo('.action-button-text-second',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1)
            tl.fromTo('.mission-tree',{ opacity:0 }, { opacity:1, ease:"power3.inOut", duration: 1 }, 1) 
    }, []); 

    return (
        <div className="mission-content">   
            <Container>
                <Row>
                    <Col className="mission-header">
                        <h1 className="header">Mission</h1>
                    </Col>
                </Row>
                <Row> 
                    <Col className="mission-text-container">
                        <p className="mission-text">Our mission is to maximize virtual sample utilization throughout the value chain in order to increase speed, reduce sampling waste and costs while allowing for better and  faster decision making</p>
                    </Col>
                </Row>
                <Row><hr size="1" width="100%" color="white" id="line"></hr></Row>
                <Row>
                    <Col className="software-header">
                        <h3 className="header">Supports 3D content created in</h3>
                    </Col>
                </Row>
                <Row className="software-logo-group justify-content-md-center"> 
                    <Col className="software-logo-container" md="auto">
                        <img className="software-logo" src={CLOLogo} alt="CLOLogo"/>
                    </Col>
                    <Col className="software-logo-container" md="auto">
                        <img className="software-logo" src={BROWZWEARLogo} alt="BROWZWEARLogo"/>
                    </Col>
                    <Col className="software-logo-container" md="auto">
                        <img className="software-logo" src={LectraLogo} alt="LectraLogo"/>
                    </Col>
                    <Col className="software-logo-container" md="auto">
                        <img className="software-logo" src={OptitexLogo} alt="OptitexLogo"/>
                    </Col>
                </Row>
                <Row><hr size="1" width="100%" color="white" id="line"></hr></Row>
                <Row className="action-container">
                    <Col align="center" md={12} lg={6} className="action-container-inner">
                        <div className="action-text-container"><h3 className="action-text action-text-first"><img src={Cube} className="mission-cube" alt=""></img>Adopt an end-to-end 3D workflow</h3></div>
                        <Button className="button" href="./workflow"><span className="action-button-text-first">Discover more</span><img src={Arrow} className="arrow action-arrow-first" alt=""></img></Button>
                    </Col>
                    <Col align="center" md={12} lg={6} className="action-container-inner">
                        <div className="action-text-container"><h3 className="action-text action-text-second"><img src={Tree} className="mission-tree" alt=""></img>Become more sustainable</h3></div>
                        <Button className="button" href="./sustainability"><span className="action-button-text-second">Find out how</span><img src={Arrow} className="arrow action-arrow-second" alt=""></img></Button>
                    </Col>
                </Row>
            </Container>
        </div>   
    )
}
export default Approach;